.subAdmin-container {
    width: 90%;
    margin: auto;
    padding-top: 3rem
}
.subAdmin-tableHeader {
    background-color: #FF4F4F !important;
    background: #FF4F4F !important;
    color: white;
    border-color: #212529;
}
.subAdmin-tableHeader td {
    padding: 20px;
    
}
.subAdmin-tableBody {
    color: white;
    border-color: #212529;
    background-color: #212529;
}
.subAdmin-topSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.subAdmin-topSection h4 {
    color: white;
}

.subAdmin-button {
    width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    background: #FF4F4F;
    border: none;
    border-radius: 8px;
    padding: 8px 10px;
    color: white;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    /* margin-top: 2rem; */
}
.subAdmin-button span {
    margin-left: 10px;
}


.subAdmin-modal {
    outline: none !important;
    border: none !important;
    border-left: none;
    z-index: 9999;

}


.modal-content {
    border: none;
}

.basic-multi-select {
    padding: 0;
    outline: none;

}
.select__control {
    background-color: #323539 !important;
    color: #212529;
    border: none !important;
    border-radius: 6px !important;
}
.select__control:hover {
    border: none !important;
}

.btn-close {
    color: white !important;
}
.css-t3ipsp-control {
    box-shadow: none !important;
}
.css-t3ipsp-control:hover {
    border: none !important;
}
.select__menu {
    background-color: #323539 !important;
    color: #ff4f4f !important;
}
.subAdmin-modal-header {
    background-color: #212529 !important;
    outline: none;
    border: none;
    color: white !important;
}

.subAdmin-modal-body, .subAdmin-modal-footer {
    background-color: #212529;
    outline: none;
    border: none;
    color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.subAdmin-modal-input, .basic-multi-select {
    background-color: #323539 !important;
    outline: none !important;
    border: none !important;
    color: white !important;
}

.subAdmin-modal-footer {
    justify-content: flex-start;
}

.subAdmin-tableRow {
    cursor: pointer;
}

.subAdmin-edit {
    color: green !important;
}
.subAdmin-delete {
    color: red !important;
}