.loginPage-container {
    /* width: 100%; */
    height: 100vh;
    background: linear-gradient(
            90deg,
            rgba(19, 19, 19, 0.5) 0%,
            rgba(24, 24, 24, 0.356) 96%
        ),
        url("../../assets/bg-img.png") center;

    display: flex;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
}

.loginPage-formDiv {
    display: flex;
    width: 60%;
    min-height: 400px;
    /* margin-top: 9%; */
    /* border: 1px solid #cdcdcd; */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 13px 7px rgba(0, 0, 0, 0.1);
}

.left {
    flex: 1;
    background-color: #121212;
    /* background: linear-gradient(
            90deg,
            rgba(19, 19, 19, 0.348) 0%,
            rgba(17, 17, 17, 0.283) 96%
        ),
        url("../../assets//login.jpg") center; */
    background-size: cover;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid white;
}

.left p {
    color: #ffffff;
    font-size: 18px;
    
}
.right {
    flex: 1;
    background: #121212;
    padding: 30px;
}

@media only screen and (max-width: 600px) {
    .loginPage-formDiv {
        flex-direction: column;
    }
}

@media only screen and (min-width: 600px) {
    .loginPage-formDiv {
        width: 60%;
    }
}
@media only screen and (min-width: 768px) {
    .loginPage-formDiv {
        width: 45%;
    }
}
@media only screen and (min-width: 992px) {
    .loginPage-formDiv {
        width: 40%;
    }
    .left {
        flex: 1;
    }
    .right {
        padding: 10px;
        flex: 1;
    }
}
.loginPage-formHeaderDiv {
    text-align: center;
    margin: 2rem auto;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.logoImage {
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
}

.brandName {
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    color: #f47e20;
    opacity: 0.7;
    letter-spacing: 0.4px;
}

.loginPage-headerTitle {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
    color: white;
}
.loginPage-headerSub {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.3px;
    color: white;
}

.loginPage-formContent {
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 3rem;
}

.loginPage-formFieldDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
}

.loginPage-inputLabel {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: white;
    margin: 0px 5px;
    margin-bottom: 3px;
}

.forgotPass {
    font-size: 10px;
    text-transform: capitalize;
}

.forgotPass:hover {
    color: #f47e20;
    text-decoration-line: underline;
}

.loginPage-inputField {
    width: 100%;
    padding: 14px;
    background: transparent;
    border: none;
    color: #fff;
    border-bottom: 1px solid #FF4F4F;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
}

.loginPage-inputField::placeholder {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: white;
    opacity: 0.9;
}

.loginPage-passDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.loginPage-submitBtnDiv {
    width: 100%;
    margin: auto;
    margin-top: 1rem;
}

.loginPage-submitBtn {
    width: 100%;
    background: #FF4F4F;
    box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
    border: none;
    border-radius: 20px;
    padding: 15px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    cursor: pointer;
}
