body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-m9mp0w-MuiToolbar-root {
  /* background-color: #21222d !important; */
  background-color: #121212 !important;
}
.css-xgqgls {
  /* background-color: #21222d !important; */
  background-color: #121212 !important;
}

.css-9mgopn-MuiDivider-root {
  border-color: rgb(255 252 252 / 28%) !important;
}
.dashboard-outlet {
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.61) 0%,
      rgba(24, 24, 24, 0.719) 96%
    ),
    url("./assets/bg-img.png") center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.go2072408551 {
  background-color: #d6ff00 !important;
}
.ql-tooltip {
  left: 0 !important;
}

.quill {
  /* width: 700px; */
  background-color: #121212 !important;
  height: 480px;
  padding-bottom: 50px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}

.quill p {
  color: #fff !important;
}
.quill strong {
  color: #fff !important;
}
.quill span {
  color: #fff !important;
}
.quill h2 {
  color: #fff !important;
}
.quill h3 {
  color: #fff !important;
}
.quill h4 {
  color: #fff !important;
}
.quill h5 {
  color: #fff !important;
}
.quill h6 {
  color: #fff !important;
}

.quill em {
  color: #fff !important;
}

.quill small {
  color: #fff !important;
}
.quill ul {
  color: #fff !important;
}
.quill ol {
  color: #fff !important;
}
.quill li {
  color: #fff !important;
}

.quill h1 {
  color: #fff !important;
}
.ql-container.ql-snow {
  /* width: 700px; */
  /* height: 300px; */
  border: none !important;
}
.quill0 .ql-container.ql-snow {
  border: none !important;
  border-radius: 0 !important;
  /* width: 500px !important; */
  /* height: 100px; */
}
.ql-snow .ql-picker-options {
  background-color: #aee2ff;
}

.ql-snow .ql-picker {
  color: "#fff" !important;
}
.cursor {
  cursor: pointer;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid #d6d8da;
  color: #fff;
  border-radius: 6px;
}
.btn-outline-success-2 {
  background-color: #fff;
  color: #455e2a;
  border: 1px solid #455e2a;
  outline: none;
  font-family: Montserrat;
  font-weight: 400;
  border-radius: 8px;
  font-size: 16px;
  padding: 8px 10px;
}
.btn-outline-success-2:hover {
  background-color: #455e2a;
  color: #fff;
  border: 1px solid #455e2a;
  outline: none;
  font-family: Montserrat;
  font-weight: 400;
  border-radius: 8px;
  font-size: 16px;
  padding: 8px 10px;
}
.tags-input:focus-within {
  border: 1px solid #ff4f4f;
}

.tags-input input {
  flex: 1;
  color: #fff;
  border: none;
  height: 46px;
  font-size: 14px;
  height: 57px;
  padding: 13px 15px;
  background-color: transparent;
}

.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #ff4f4f;
}

.tag .tag-title {
  margin-top: 3px;
}

.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #ff4f4f;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

.text-black2 h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
ul,
ol {
  color: #000;
}

.text-black2 p {
  color: #000;
}

#description_id .ql-container {
  font-size: 14px;
}

.optionContainer {
  background-color: #000 !important;
}
.multiSelectContainer li {
  padding: 10px;
  color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
