.accordion-button {
  background-color: inherit !important;
  color: inherit !important;
}
.accordion-button:not(.collapsed) {
  background-color: inherit !important;
  color: inherit !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  border-color: #121212 !important;
  border: none !important;
  box-shadow: none !important;
}

.accordion-item {
  background-color: "#155B8" !important;
  border: none !important;
}
.accordion-body ul {
  margin-top: 0px !important;
  padding-left: 0px !important;
}
.sidebar-logo {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  width: 90%;
  /* border: 2px solid white; */
  justify-content: center;
}

/* .sidebar-logo img:first-child {
    width: 25% !important;
}
.sidebar-logo img:last-child {
    width: 80% !important;
} */
.sidebar-logo p {
  margin-top: 15px !important;
  color: #fff;
}
