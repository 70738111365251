/* Table Styles */
.Table-Container {
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-wrapper {
    max-width: 100%;
    max-height: 70vh;
    margin: auto;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
}

/* width */
.table-wrapper::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.table-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 10px; */
}

/* Handle */
.table-wrapper::-webkit-scrollbar-thumb {
    background: #a8b1b2;
    border-radius: 5px;
}

/* Handle on hover */
.table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #8c9899;
}

.fl-table {
    border-radius: 6px;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    
    background-color: white;
}

.fl-table td,
.fl-table th {
   
    overflow-wrap: break-word;
    text-align: left;
    padding: 12px 10px;
}
.action-btn {
    overflow: visible !important;
    text-overflow: clip !important;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    border-bottom: 2px solid #f8f8f8;
    font-size: 12px;
}

.fl-table thead th {
    color: #333333;
    font-weight: 500;
    background: #fff;
    border-right: 1px solid #f8f8f8;
    border-bottom: 3px solid #f8f8f8 !important;
}

/* .fl-table thead tr {
    border-bottom: 2px solid #f8f8f8;
  } */

.fl-table thead th:nth-child(odd) {
    color: #333333;
    background: #fff;
}

.fl-table tr:nth-child(even) {
    background: #fff;
}

.artist-blockBtn {
    padding: 2px 15px;
    border: 2px solid #1c1b4d;
    border-radius: 31px;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    color: #1c1b4d;
    outline: none;
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

/* buttons */
.delete-btn {
    color: white;
    background-color: red;
    outline: none;
    border: 1px solid red;
    cursor: pointer;
    border-radius: 4px;
}
.edit-btn {
    color: white;
    background-color: green;
    outline: none;
    border: 1px solid green;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 5px;
}
.image-td img {
    width: 100px;
    height: 70px;
    border-radius: 10px;
}
.video-td {
    cursor: pointer;
}
