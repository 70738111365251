/* main Container */
.property-container {
    width: 90%;
    margin: auto;
}

/* First section */

.property-firstSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
}
/* First section search  */
.property-searchDiv {
    width: 50%;
    padding: 8px 15px;
    border: 2px solid #1c1b4d;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.searchIcon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.property-searchInput {
    width: 100%;
    /* padding: 8px 15px; */
    border: none;
    box-sizing: border-box;
    /* border-radius: 31px; */
    background-color: inherit;
    outline: none;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

#searchInput:before {
    content: url("../../assets/searchIcon.svg");
    padding-right: 10px;
}

.property-searchInput::placeholder {
    color: #FF4F4F;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    opacity: 0.9;
}

/* First section Add property  */
.property-addpropertyDiv {
    width: 20%;
}
.add_user_button {
    width: 25%;
    display: flex;
    gap: 10px;
}

.property-addBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    background: #FF4F4F;
    border: none;
    border-radius: 10px;
    padding: 8px 10px;
    color: white;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.property-addBtn span {
    margin-left: 10px;
}
.property-addIcon {
    color: black;
}
