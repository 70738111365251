.previewContainer h1 {
  color: #fff;
}
.previewContainer p {
  color: #fff;
}
.previewContainer span {
  color: #fff !important;
}
.previewContainer {
  background-color: #121212;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.previewContainer img {
  width: 100%;
  height: 450px;
}

.previewContainer iframe {
  width: 100%;
  height: 450px;
}

.previewContainer a {
  color: #ff1818 !important;
}

.previewContainer a:hover {
  color: #ff1818;
}
.previewContainer a strong {
  color: #ff1818 !important;
  text-decoration: underline;
}

.category {
  color: #ff1818;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 10px;
  border: 1px solid #ff1818;
}
