/* Main contianer */
.addproperty-container {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    /* background: #f1f1f1; */
}
.addbulk-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* For validation */
#red-border {
    border: 1px solid red;
}

/* form details container */
.addproperty-personalDetails {
    width: 70%;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 3rem;
}
.addbulkuser {
    width: 45%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 3rem;
    background-color: white;
    box-shadow: 19px 20px 13px -3px rgb(0 0 0 / 10%);
    border-radius: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

/* form row */
.addproperty-alignRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}
.addbulk-alignRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Input Filed div */
.addproperty-inputFieldDiv {
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1.5rem;
}

/* Input field Label */
.addproperty-inputLabel {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #fff;
    margin: 0px 5px;
    margin-bottom: 1px;
}

/* Input field Input*/
.addproperty-inputField {
    border: 0.5px solid #FF4F4F;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    padding: 13px 15px;
    outline: none;
}

.addproperty-inputField::placeholder {
    color: #fff;
    opacity: 0.8;
}
.addproperty-inputFieldDiv-image {
    padding-top: 15px;
    width: 100%;
    text-align: center;
}

/* Text Area Input Filed div */
.addproperty-textFieldDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
}

/* Text Area Input Filed*/
.addproperty-textField {
    border: 0.5px solid #FF4F4F;
    box-sizing: border-box;
    width: 100%;
    min-height: 150px;
    border-radius: 5px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    padding: 13px 15px;
    outline: none;
}
.addproperty-textField::placeholder {
    color: #fff;
    opacity: 0.8;
}
/* Submit btn div */

.addproperty-submitDetailDiv {
    width: 40%;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}
.addproperty-submitDetailDivMeal {
    width: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 3rem;
}
/* Submit btn Detail */
.addproperty-submitDetailBtn {
    width: fit-content;
    border: none;
    outline: none;
    background: #FF4F4F;
    padding: 10px 20px;
    box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
    border-radius: 10px;
    font-family: Mulish;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: white;
    cursor: pointer;
}
.addproperty-submitAddMeal {
    width: 50%;
    border: none;
    outline: none;
    background: #d6ff00;
    padding: 15px;
    box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
    border-radius: 10px;
    font-family: Mulish;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000;
    cursor: pointer;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 29px 35px rgba(0, 0, 0, 0.1);
}
.upload-container img {
    width: 90%;
}
.upload-container p {
    font-size: 18px;
}
.exampleDownload {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    margin-top: -24px;
    text-decoration: none;
    cursor: pointer;
    color: black;
}

.exampleDownload img {
    width: 2%;
    margin-right: 10px;
}
.note-bulk {
    text-align: center;
    margin-top: 15px;
}
.disabled-color {
    cursor: none;
    color: lightgrey;
}
.select-item {
    background: transparent !important;
}

.select-item option {
    background-color: #21222d;
}
.addproperty-inputFieldDivMealType {
    width: 46%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1.5rem;
}

.add-content {
    width: fit-content;
    border: none;
    outline: none;
    background: #d6ff00;
    padding: 15px;
    box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
    border-radius: 10px;
    font-family: Mulish;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000;
    cursor: pointer;
}

.addproperty-inputFieldMealType {
    border: 0.5px solid #d6ff00;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    padding: 13px 15px;
    outline: none;
}
.meal-select {
    display: flex;
    flex-direction: column;
}

.btn-success {
    background-color: green;
    color: #fff;
    border-radius: 10px;
}

.meal-title {
    display: flex;
    gap: 10px;
    color: white;
}


.genreSelect{
   width: 100%;
   background: transparent;
   border: red;
}
