.banner-container {
    width: 90%;
    margin: auto;
    padding-top: 3rem
}

.editBanner-container {
    width: 100%;
    padding: 0;
    margin-top: 3rem;
}

.editBanner-details {
    width: 100%;
    padding: 0;
}