.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
    align-items: center;
}
.loading-box {
    position: relative;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: transparent;
    width: 150px;
    height: 150px;
    margin-top: 1rem;
    text-align: center;
}
