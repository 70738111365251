.filter-div > button {
  color: #fffafa !important;
  background-color: #ff4f4f;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 0.9rem;
}

.filter-div > a:hover {
  color: #ff4f4f !important;
}
.filter-div-2 > button {
  color: #fffafa !important;
  background-color: transparent;
  cursor: pointer;
  margin-right: 15px;
  border-bottom: 2px solid #ff4f4f;
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 5px 15px;
  font-size: 0.9rem;
}

.filter-div-3 > button {
  color: #ff4f4f !important;
  border: 1px solid #fffafa;
  background-color: aliceblue;
  margin: 10px;
  border-bottom: 2px solid #ff4f4f;
  border-radius: 10px;
  padding: 5px 15px;
}
